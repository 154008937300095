import request from '../utils/request';

export function getMerchantsList(param) {
    return request({
        url: '/merchants-list',
        method: 'GET',
        params: param
    })
}

export function saveMerchants(data, id) {
    return request({
        url: '/merchants/'+id,
        method: 'POST',
        data
    })
}

export function deleteMerchants(id) {
    return request({
        url: '/merchants/'+id,
        method: 'DELETE'
    })
}

export function setMerchantsSort(id, sort) {
    return request({
        url: '/merchants/'+id+'/sort/'+sort,
        method: 'PUT'
    })
}

export function setMerchantsIsvalidate(id, isvalidate) {
    return request({
        url: '/merchants/'+id+'/isvalidate/'+isvalidate,
        method: 'PUT'
    })
}
